@import url(../../assets/styles/swiper-bundle.min.css);
.clear {
  clear: both;
}

body {
  overflow-x: hidden;
}

.center {
  width: 1360px;
  margin: 0 auto;
}

.comp-header-nav-item-mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  display: none;
}

.jason-map {
  z-index: 9999999;
}

.index-vote .vote-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.index-vote .vote-img {
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1001;
  transition: .3s;
  width: 325px;
}

@media (max-width: 1366px) {
  .index-vote .vote-img {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .index-vote .vote-img {
    width: 275px;
  }
}

@media (max-width: 767px) {
  .index-vote .vote-img {
    width: 250px;
  }
}

.index-vote .vote-img a img {
  width: 100%;
  height: auto;
  transition: .3s;
}

.index-vote .vote-img .vote-close {
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
}

.index-vote .vote-img.small-vote {
  top: auto;
  left: auto;
  bottom: 10px;
  right: 10px;
  transform: translate3d(0, 0, 0);
  width: 100px;
}

@media (max-width: 1366px) {
  .index-vote .vote-img.small-vote {
    width: 93.33333px;
  }
}

@media (max-width: 991px) {
  .index-vote .vote-img.small-vote {
    width: 86.66667px;
  }
}

@media (max-width: 767px) {
  .index-vote .vote-img.small-vote {
    width: 80px;
  }
}

.index-vote .vote-img.small-vote .vote-close {
  display: none;
}

.banner {
  height: 100vh;
}

.banner .banner-swiper {
  width: 100%;
  height: 100%;
}

.banner .banner-swiper .swiper-slide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.banner .banner-swiper .swiper-slide .banner-item-pic {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .banner-swiper .swiper-slide .banner-item-pic.mobile {
  display: none;
}

.banner .banner-swiper .swiper-slide .banner-item-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-left: 7.29167vw;
  padding-top: 200px;
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text {
    padding-top: 166.66667px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text {
    padding-top: 133.33333px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text {
    padding-top: 100px;
  }
}

.banner .banner-swiper .swiper-slide .banner-item-text h1 {
  color: #fff;
  font-family: font-title;
  transition: all .6s ease .3s;
  opacity: 0;
  transform: translateX(20%);
  font-size: 32px;
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h1 {
    font-size: 26.66667px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h1 {
    font-size: 21.33333px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h1 {
    font-size: 16px;
  }
}

.banner .banner-swiper .swiper-slide .banner-item-text h2 {
  color: #fff;
  font-family: font-title;
  transition: all .6s ease .4s;
  opacity: 0;
  transform: translateX(25%);
  line-height: 1.2;
  font-size: 68px;
  margin-bottom: 10px;
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h2 {
    font-size: 52.66667px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h2 {
    font-size: 37.33333px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text h2 {
    font-size: 22px;
  }
}

.banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
  border: 2px solid #fff;
  text-align: center;
  line-height: 1.8;
  font-family: font-title;
  transition: all .6s ease .5s;
  opacity: 0;
  transform: translateX(25%);
  font-size: 22px;
  width: 210px;
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    font-size: 19.33333px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    font-size: 16.66667px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    font-size: 14px;
  }
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    width: 186.66667px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    width: 163.33333px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more {
    width: 140px;
  }
}

.banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a {
  display: block;
  transition: 0.2s;
  color: #fff;
}

.banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a i {
  margin-left: 10px;
  transition: 0.2s;
  font-size: 24px;
}

@media (max-width: 1366px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a i {
    font-size: 21.33333px;
  }
}

@media (max-width: 991px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a i {
    font-size: 18.66667px;
  }
}

@media (max-width: 767px) {
  .banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a i {
    font-size: 16px;
  }
}

.banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a:hover {
  box-shadow: 8px 8px 0 #fff;
}

.banner .banner-swiper .swiper-slide .banner-item-text .banner-item-more a:hover i {
  margin-left: 30px;
}

.banner .banner-swiper .swiper-slide.swiper-slide-active .banner-item-text h1,
.banner .banner-swiper .swiper-slide.swiper-slide-active .banner-item-text h2,
.banner .banner-swiper .swiper-slide.swiper-slide-active .banner-item-text .banner-item-more {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .banner .banner-swiper .swiper-slide .banner-item-pic.pc {
    display: none;
  }
  .banner .banner-swiper .swiper-slide .banner-item-pic.mobile {
    display: block;
  }
}

.banner .index-banner-pagination {
  padding-top: 5px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);
  margin-right: 28px;
}

@media (max-width: 767px) {
  .banner .index-banner-pagination {
    margin-right: 0;
  }
}

.banner .index-banner-pagination .list {
  transition-duration: 0.3s;
  flex-direction: column;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .banner .index-banner-pagination .list {
    flex-direction: row;
  }
}

@media (max-width: 1366px) {
  .banner .index-banner-pagination .list {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .banner .index-banner-pagination .list {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .banner .index-banner-pagination .list {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .banner .index-banner-pagination .list {
    margin-top: 12px;
  }
}

.banner .index-banner-pagination .item {
  position: relative;
  cursor: pointer;
}

.banner .index-banner-pagination .item:not(:last-child) {
  margin-right: 16px;
}

.banner .index-banner-pagination .circle {
  fill: transparent;
  transition: all 0.3s;
  transform: scale(0.35);
  transform-origin: center center;
}

.banner .index-banner-pagination .circle.anime {
  stroke: #ff3615;
  stroke-width: 3px;
  stroke-dasharray: 50.24;
  stroke-dashoffset: 50.24;
  opacity: 0;
  transition: all 0.3s;
}

.banner .index-banner-pagination .circle.bg {
  fill: #fff;
  stroke-width: 2px;
}

.banner .index-banner-pagination .item.active {
  transform: scale(1);
}

.banner .index-banner-pagination .item.active .circle {
  transform: scale(1);
}

.banner .index-banner-pagination .item.active .circle.anime {
  opacity: 1;
  animation-name: active;
  animation-timing-function: linear;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.banner .index-banner-pagination .item.active .circle.bg {
  fill: transparent;
  stroke: #fff;
}

@keyframes active {
  0% {
    stroke-dashoffset: 50.24px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rain {
  0% {
    transform: translateY(5px);
    opacity: 1;
  }
  80% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@media (max-width: 991px) {
  .comp-header-lang {
    display: none !important;
  }
}

.about-cont {
  position: relative;
  margin-bottom: 85px;
}

@media (max-width: 1366px) {
  .about-cont {
    margin-bottom: 63.33333px;
  }
}

@media (max-width: 991px) {
  .about-cont {
    margin-bottom: 41.66667px;
  }
}

@media (max-width: 767px) {
  .about-cont {
    margin-bottom: 20px;
  }
}

.about {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  font-family: font-title;
  position: relative;
}

.about::after {
  content: '';
  display: block;
  height: 0;
  clear: both;
}

.about .title {
  color: #2456ab;
  font-size: 40px;
  padding-top: 90px;
  margin-bottom: 60px;
}

@media (max-width: 1366px) {
  .about .title {
    font-size: 33.33333px;
  }
}

@media (max-width: 991px) {
  .about .title {
    font-size: 26.66667px;
  }
}

@media (max-width: 767px) {
  .about .title {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .about .title {
    padding-top: 73.33333px;
  }
}

@media (max-width: 991px) {
  .about .title {
    padding-top: 56.66667px;
  }
}

@media (max-width: 767px) {
  .about .title {
    padding-top: 40px;
  }
}

@media (max-width: 1366px) {
  .about .title {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .about .title {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .about .title {
    margin-bottom: 30px;
  }
}

.about .title span {
  position: relative;
}

.about .title span::after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #d1d7e9;
  z-index: -1;
}

.about .video {
  width: 60%;
  float: left;
  box-shadow: 30px 20px 0px #efefef;
  position: relative;
}

.about .video .video-img {
  position: relative;
  cursor: pointer;
}

.about .video .video-img .img1 {
  display: block;
  width: 100%;
  height: auto;
}

.about .video .video-img .img2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.about .video .video-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  background: #000;
}

.about .video .video-cont video {
  width: 100%;
  height: 100%;
}

.about .video .video-cont.active {
  display: block;
}

.about .text {
  width: 40%;
  float: left;
}

.about .text p {
  color: #333;
  line-height: 1.8;
  padding: 10px 0;
  font-family: font-article;
}

.about .text .btn {
  border: 2px solid #000;
  width: 220px;
  text-align: center;
  line-height: 2.2;
  margin: 0 auto;
  margin-top: 30px;
  transition: 0.15s linear;
}

@media (max-width: 1366px) {
  .about .text .btn {
    width: 200px;
  }
}

@media (max-width: 991px) {
  .about .text .btn {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .about .text .btn {
    width: 160px;
  }
}

@media (max-width: 1366px) {
  .about .text .btn {
    margin-top: 26.66667px;
  }
}

@media (max-width: 991px) {
  .about .text .btn {
    margin-top: 23.33333px;
  }
}

@media (max-width: 767px) {
  .about .text .btn {
    margin-top: 20px;
  }
}

.about .text .btn a {
  color: #000;
  font-size: 22px;
  display: block;
  transition: 0.15s linear;
  font-family: font-title;
}

@media (max-width: 1366px) {
  .about .text .btn a {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .about .text .btn a {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .about .text .btn a {
    font-size: 16px;
  }
}

.about .text .btn a i {
  font-size: 22px;
  transition: 0.2s;
}

@media (max-width: 1366px) {
  .about .text .btn a i {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .about .text .btn a i {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .about .text .btn a i {
    font-size: 16px;
  }
}

.about .text .btn:hover {
  box-shadow: 5px 5px 0px #000;
}

.about .text .btn:hover i {
  margin-left: 18px;
}

@media (max-width: 991px) {
  .about .video {
    width: 100%;
  }
  .about .text {
    width: 100%;
    margin-top: 20px;
  }
  .pl-16 {
    padding-left: 0 !important;
  }
  .float {
    display: none;
  }
}

@media (max-width: 768px) {
  .about .video {
    box-shadow: none;
  }
  .about .text {
    margin-top: 0;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text h1 {
    color: #fff;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text h2 {
    color: #fff;
  }
}

@media (max-width: 640px) {
  .about .text {
    padding-top: 0;
  }
}

@media (max-width: 378px) {
  .about .video {
    height: 240px;
  }
  .about .text {
    margin-top: 0;
  }
}

.float {
  width: 128px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.float img {
  width: 100%;
}

@media (max-width: 1920px) {
  .float {
    width: 90px;
  }
}

@media (max-width: 1600px) {
  .float {
    width: 80px;
  }
}

@media (max-width: 1366px) {
  .float {
    width: 70px;
  }
}

@media (max-width: 1024px) {
  .float {
    width: 70px;
  }
}

.pc-product {
  width: 100%;
  height: auto;
}

.pc-product .max_list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.pc-product .max_list li {
  width: 33.33vw;
  height: 100%;
  cursor: pointer;
  transition: 0.2s linear;
  flex-shrink: 0;
}

.pc-product .max_list li .border_top {
  width: 100%;
  height: 30px;
  position: relative;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .border_top {
    height: 26.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .border_top {
    height: 23.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .border_top {
    height: 20px;
  }
}

.pc-product .max_list li .border_top .back {
  width: 100%;
  height: 0px;
  transition: 0.2s linear;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 8;
}

.pc-product .max_list li .img {
  width: calc(100% + 1px);
  height: auto;
  overflow: hidden;
  position: relative;
}

.pc-product .max_list li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.pc-product .max_list li .img .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.pc-product .max_list li .img .mask img {
  width: 100%;
  filter: grayscale(100%);
  transition: 0.3s linear;
  opacity: 0.3;
}

.pc-product .max_list li .img .mask .desc {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  padding-left: 130px;
  padding-top: 108px;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc {
    padding-left: 96.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc {
    padding-left: 63.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc {
    padding-left: 30px;
  }
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc {
    padding-top: 84.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc {
    padding-top: 61.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc {
    padding-top: 38px;
  }
}

.pc-product .max_list li .img .mask .desc h1 {
  color: #fff;
  font-size: 80px;
  margin-bottom: 100px;
  font-family: font-title;
  line-height: 0.8;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc h1 {
    font-size: 58.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc h1 {
    font-size: 37.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc h1 {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc h1 {
    margin-bottom: 76.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc h1 {
    margin-bottom: 53.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc h1 {
    margin-bottom: 30px;
  }
}

.pc-product .max_list li .img .mask .desc dl dt {
  width: 100%;
  line-height: 1.8;
  transition: 0.2s linear;
  position: relative;
  z-index: 99;
}

.pc-product .max_list li .img .mask .desc dl dt .back {
  position: absolute;
  width: 0;
  height: 100%;
  line-height: 1.8;
  top: 0;
  left: 0;
  z-index: 9;
  transition: 0.25s ease-out;
}

.pc-product .max_list li .img .mask .desc dl dt a {
  color: #fff;
  font-size: 24px;
  padding-left: 8px;
  transition: 0.2s linear;
  font-family: font-title;
  position: relative;
  z-index: 99;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc dl dt a {
    font-size: 20.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc dl dt a {
    font-size: 17.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc dl dt a {
    font-size: 14px;
  }
}

.pc-product .max_list li .img .mask .desc .right_icon {
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .25s;
  margin-top: 82px;
  margin-left: -48px;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-top: 71.33333px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-top: 60.66667px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-top: 50px;
  }
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-left: -38.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-left: -29.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc .right_icon {
    margin-left: -20px;
  }
}

.pc-product .max_list li .img .mask .desc .right_icon i {
  font-size: 48px;
}

@media (max-width: 1366px) {
  .pc-product .max_list li .img .mask .desc .right_icon i {
    font-size: 38.66667px;
  }
}

@media (max-width: 991px) {
  .pc-product .max_list li .img .mask .desc .right_icon i {
    font-size: 29.33333px;
  }
}

@media (max-width: 767px) {
  .pc-product .max_list li .img .mask .desc .right_icon i {
    font-size: 20px;
  }
}

.pc-product .max_list li:hover {
  position: relative;
  z-index: 9;
}

.pc-product .max_list li:nth-child(1) .desc h1:nth-child(1),
.pc-product .max_list li:nth-child(3) .desc h1:nth-child(1) {
  margin-bottom: 0;
}

.pc-product .max_list li:nth-child(1):hover .mask .desc h1 {
  color: #fb7c2f;
}

.pc-product .max_list li:nth-child(2):hover .mask .desc h1 {
  color: #ff3615;
}

.pc-product .max_list li:nth-child(3):hover .mask .desc h1 {
  color: #17abf1;
}

.pc-product .max_list li:nth-child(4):hover .mask .desc h1 {
  color: #1e4cd5;
}

.pc-product .max_list li:hover .img .mask img {
  filter: none;
  transition: 0.2s linear;
  opacity: 1;
}

.pc-product .max_list li:nth-child(1) .desc dl dt:hover .back {
  background: #fb7c2f;
  width: 100%;
}

.pc-product .max_list li:nth-child(2) .desc dl dt:hover .back {
  background: #ff3615;
  width: 100%;
}

.pc-product .max_list li:nth-child(3) .desc dl dt:hover .back {
  background: #17abf1;
  width: 100%;
}

.pc-product .max_list li:nth-child(4) .desc dl dt:hover .back {
  background: #1e4cd5;
  width: 100%;
}

.pc-product .max_list .desc dl dt:hover a {
  color: #000 !important;
}

.pc-product .max_list li:hover .img .mask .desc .right_icon {
  opacity: 0;
  visibility: visible;
  margin-left: 0;
}

.product_title {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  color: #fb7c2f;
  font-family: font-title;
  font-size: 40px;
  margin-bottom: 60px;
}

@media (max-width: 1366px) {
  .product_title {
    font-size: 33.33333px;
  }
}

@media (max-width: 991px) {
  .product_title {
    font-size: 26.66667px;
  }
}

@media (max-width: 767px) {
  .product_title {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .product_title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .product_title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .product_title {
    margin-bottom: 0px;
  }
}

.product_title span {
  position: relative;
}

.product_title span::after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fef2cc;
  z-index: -1;
}

.xscroll {
  position: relative;
  overflow: visible;
}

.xscroll .xscroll-tab-cont {
  width: 100%;
  top: 0;
  bottom: auto;
  left: 0;
  position: absolute;
}

.xscroll .xscroll-tab-cont.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.xscroll .xscroll-tab-cont.abs {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
}

.xscroll .xscroll-tab-cont .tab {
  top: 80px;
  left: 0;
  z-index: 1;
  width: 100%;
  position: absolute;
  margin-top: 0;
}

@media (max-width: 1366px) {
  .xscroll .xscroll-tab-cont .tab {
    margin-top: 60px;
  }
}

@media (max-width: 991px) {
  .xscroll .xscroll-tab-cont .tab {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .xscroll .xscroll-tab-cont .tab {
    margin-top: 30px;
  }
}

.xscroll .xscroll-tab-cont .tab .tab-cont {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
}

@media (max-width: 1366px) {
  .xscroll .xscroll-tab-cont .tab .tab-cont {
    display: none;
  }
}

.xscroll .xscroll-cont {
  display: flex;
  overflow: visible;
  height: 100vh;
  align-items: center;
}

.xscroll .xscroll-cont .x {
  display: flex;
  overflow: visible;
}

.mob-product {
  display: none;
}

.mob-product .min_list li {
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media (max-width: 415px) {
  .desc {
    padding: 10px 0 0 10px !important;
  }
  .desc h1 {
    margin-bottom: 10px !important;
  }
  .desc .right_icon {
    margin-top: 10px !important;
  }
}

@media (max-width: 768px) {
  .product.pc-product {
    display: none;
  }
  .product.mob-product {
    display: block;
  }
  .product .min_list {
    display: block;
    width: 94%;
    height: auto;
    margin: 20px auto 0;
    margin-top: 30px;
  }
  .product .min_list li {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .product .min_list li::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  .product .min_list li h1 {
    font-size: 20px;
    color: #fff;
    font-family: font-title;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }
  .product .min_list li dl {
    position: relative;
    z-index: 1;
  }
  .product .min_list li dl dd {
    line-height: 24px;
  }
  .product .min_list li dl dd a {
    font-size: 16px;
    color: #fff;
    font-family: font-title;
    display: block;
  }
}

@media (max-width: 991px) {
  .product .product_title .line {
    margin-bottom: 6px;
  }
}

.index-news_title {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  color: #ff3615;
  font-family: font-title;
  font-size: 40px;
  margin-top: 100px;
}

@media (max-width: 1366px) {
  .index-news_title {
    font-size: 33.33333px;
  }
}

@media (max-width: 991px) {
  .index-news_title {
    font-size: 26.66667px;
  }
}

@media (max-width: 767px) {
  .index-news_title {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .index-news_title {
    margin-top: 83.33333px;
  }
}

@media (max-width: 991px) {
  .index-news_title {
    margin-top: 66.66667px;
  }
}

@media (max-width: 767px) {
  .index-news_title {
    margin-top: 50px;
  }
}

.index-news_title span {
  position: relative;
}

.index-news_title span::after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ffd7d0;
  z-index: -1;
}

.news {
  padding-left: 270px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.news .swiper-wrapper {
  box-sizing: border-box !important;
}

.news ul li {
  overflow: hidden;
  position: relative;
}

.news ul li .img {
  width: 100%;
  height: 100%;
}

.news ul li .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  transition: all .3s ease-out 0s;
}

.news ul li .img b {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: all .3s ease-out 0s;
}

.news ul li .img .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.news ul li .img .con {
  min-height: 400px;
  position: relative;
  z-index: 999;
  background: #efefef;
  transition: all .3s ease-out 0s;
  padding: 36px;
}

@media (max-width: 1366px) {
  .news ul li .img .con {
    padding: 29px;
  }
}

@media (max-width: 991px) {
  .news ul li .img .con {
    padding: 22px;
  }
}

@media (max-width: 767px) {
  .news ul li .img .con {
    padding: 15px;
  }
}

.news ul li .img .con .date {
  display: flex;
  align-items: center;
}

.news ul li .img .con .date .spot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff3615;
  transition: all .3s ease-out 0s;
}

.news ul li .img .con .date .date_time {
  flex: 1;
  text-align: right;
  transition: all .3s ease-out 0s;
  font-family: font-article;
}

.news ul li .img .con h1 {
  font-size: 28px;
  margin-top: 36px;
  margin-bottom: 36px;
  transition: all .3s ease-out 0s;
  font-family: font-title;
  color: #333333;
}

@media (max-width: 1366px) {
  .news ul li .img .con h1 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .news ul li .img .con h1 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .news ul li .img .con h1 {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .news ul li .img .con h1 {
    margin-top: 29.33333px;
  }
}

@media (max-width: 991px) {
  .news ul li .img .con h1 {
    margin-top: 22.66667px;
  }
}

@media (max-width: 767px) {
  .news ul li .img .con h1 {
    margin-top: 16px;
  }
}

@media (max-width: 1366px) {
  .news ul li .img .con h1 {
    margin-bottom: 29.33333px;
  }
}

@media (max-width: 991px) {
  .news ul li .img .con h1 {
    margin-bottom: 22.66667px;
  }
}

@media (max-width: 767px) {
  .news ul li .img .con h1 {
    margin-bottom: 16px;
  }
}

.news ul li .img .con .desc {
  line-height: 1.4;
  transition: all .3s ease-out 0s;
  font-family: font-article;
}

.news ul li :hover .con {
  background: none;
}

.news ul li :hover .img img {
  transform: translate3d(-50%, -50%, 0) scale(1.05);
}

.news ul li :hover .img b {
  transform: scale(1.05);
}

.news ul li :hover .img .con .date .spot {
  background: #fff;
}

.news ul li :hover .img .con .date_time {
  color: #fff;
}

.news ul li :hover .img .con h1 {
  color: #fff;
}

.news ul li :hover .img .con .desc {
  color: #fff;
}

.news .swiper-button-prev {
  left: 3% !important;
  opacity: 1;
}

.news .swiper-button-next {
  right: 3% !important;
}

@media (max-width: 1024px) {
  .news {
    padding-left: 100px;
  }
}

@media (max-width: 768px) {
  .news {
    padding-left: 0px;
    margin-top: 30px;
  }
  .news ul {
    padding-left: 0;
  }
  .news ul li {
    margin-right: 0;
  }
  .news ul li .img .con {
    background: none;
  }
  .news ul li .img .con .date, .news ul li .img .con h1, .news ul li .img .con .desc {
    color: #fff;
  }
}

@media (max-width: 414px) {
  .news ul {
    padding-left: 0;
  }
  .news ul li {
    width: 100% !important;
    margin-right: 0;
  }
}

.swiper-button-next1, .swiper-button-next2 {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
  background: url(./images/icon_right.png) no-repeat center;
  color: transparent;
}

@media (max-width: 1366px) {
  .swiper-button-next1, .swiper-button-next2 {
    width: 45px;
  }
}

@media (max-width: 991px) {
  .swiper-button-next1, .swiper-button-next2 {
    width: 36px;
  }
}

@media (max-width: 767px) {
  .swiper-button-next1, .swiper-button-next2 {
    width: 27px;
  }
}

@media (max-width: 1366px) {
  .swiper-button-next1, .swiper-button-next2 {
    height: 45px;
  }
}

@media (max-width: 991px) {
  .swiper-button-next1, .swiper-button-next2 {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .swiper-button-next1, .swiper-button-next2 {
    height: 27px;
  }
}

.swiper-button-prev1, .swiper-button-prev2 {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
  background: url(./images/icon_left.png) no-repeat center;
  color: transparent;
}

@media (max-width: 1366px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    width: 45px;
  }
}

@media (max-width: 991px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    width: 36px;
  }
}

@media (max-width: 767px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    width: 27px;
  }
}

@media (max-width: 1366px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    height: 45px;
  }
}

@media (max-width: 991px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    height: 36px;
  }
}

@media (max-width: 767px) {
  .swiper-button-prev1, .swiper-button-prev2 {
    height: 27px;
  }
}

.swiper-button-next1:focus, .swiper-button-prev1:focus, .swiper-button-next2:focus, .swiper-button-prev2:focus {
  outline: none;
}

.my-button-disabled {
  opacity: 0.6 !important;
}

.index-media-title {
  color: #25b3e8;
  font-weight: bold;
  font-family: 'font-title';
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 40px;
  padding-top: 150px;
  margin-bottom: 60px;
}

@media (max-width: 1366px) {
  .index-media-title {
    font-size: 33.33333px;
  }
}

@media (max-width: 991px) {
  .index-media-title {
    font-size: 26.66667px;
  }
}

@media (max-width: 767px) {
  .index-media-title {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .index-media-title {
    padding-top: 113.33333px;
  }
}

@media (max-width: 991px) {
  .index-media-title {
    padding-top: 76.66667px;
  }
}

@media (max-width: 767px) {
  .index-media-title {
    padding-top: 40px;
  }
}

@media (max-width: 1366px) {
  .index-media-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .index-media-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .index-media-title {
    margin-bottom: 30px;
  }
}

.index-media-title span {
  position: relative;
}

.index-media-title span::after {
  content: '';
  width: 100%;
  height: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #d3f0fa;
  z-index: -1;
}

.index-media {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1366px) {
  .index-media {
    padding-left: 13.33333px;
  }
}

@media (max-width: 991px) {
  .index-media {
    padding-left: 6.66667px;
  }
}

@media (max-width: 767px) {
  .index-media {
    padding-left: 0px;
  }
}

@media (max-width: 1366px) {
  .index-media {
    padding-right: 13.33333px;
  }
}

@media (max-width: 991px) {
  .index-media {
    padding-right: 6.66667px;
  }
}

@media (max-width: 767px) {
  .index-media {
    padding-right: 0px;
  }
}

.index-media .index-media-cont {
  position: relative;
  height: 760px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont {
    height: 606.66667px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont {
    height: 453.33333px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont {
    height: 300px;
  }
}

.index-media .index-media-cont .swiper-slide {
  background-size: cover;
  background-position: center;
}

.index-media .index-media-cont .gallery-top {
  height: 100%;
  width: 100%;
  position: relative;
}

.index-media .index-media-cont .gallery-top .media-link {
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  z-index: 2;
  transition: .3s;
  display: flex;
  bottom: 150px;
  opacity: 0;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-top .media-link {
    bottom: 126.66667px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link {
    bottom: 103.33333px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-top .media-link {
    bottom: 80px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link {
    opacity: 1;
  }
}

.index-media .index-media-cont .gallery-top .media-link .btn {
  border: 2px solid #fff;
  text-align: center;
  line-height: 2.2;
  font-family: font-title;
  transition: all .6s ease .5s;
  font-size: 30px;
  width: 290px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    font-size: 18px;
  }
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    width: 246.66667px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    width: 203.33333px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-top .media-link .btn {
    width: 160px;
  }
}

.index-media .index-media-cont .gallery-top .media-link .btn a {
  display: block;
  transition: 0.2s;
  color: #fff;
}

.index-media .index-media-cont .gallery-top .media-link .btn a i {
  margin-left: 10px;
  transition: 0.2s;
  font-size: 34px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a i {
    font-size: 28.66667px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a i {
    font-size: 23.33333px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a i {
    font-size: 18px;
  }
}

.index-media .index-media-cont .gallery-top .media-link .btn a:hover {
  box-shadow: 8px 8px 0 #fff;
}

.index-media .index-media-cont .gallery-top .media-link .btn a:hover i {
  margin-left: 30px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a:hover i {
    margin-left: 25px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a:hover i {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-top .media-link .btn a:hover i {
    margin-left: 15px;
  }
}

.index-media .index-media-cont .gallery-top:hover .media-link {
  opacity: 1;
}

.index-media .index-media-cont .gallery-thumbs {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .gallery-thumbs {
    height: 126.66667px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .gallery-thumbs {
    height: 103.33333px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .gallery-thumbs {
    height: 80px;
  }
}

.index-media .index-media-cont .gallery-thumbs .swiper-slide {
  width: 11.11%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.index-media .index-media-cont .gallery-thumbs .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.index-media .index-media-cont .gallery-thumbs .swiper-slide-thumb-active::after {
  background: rgba(0, 0, 0, 0);
}

.index-media .index-media-cont .swiper-button-next, .index-media .index-media-cont .swiper-button-prev {
  top: 43%;
  display: flex;
}

@media (max-width: 991px) {
  .index-media .index-media-cont .swiper-button-next, .index-media .index-media-cont .swiper-button-prev {
    display: none;
  }
}

.index-media .index-media-cont .swiper-button-prev:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-next:after,
.index-media .index-media-cont .swiper-button-next:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-prev:after {
  font-size: 36px;
}

@media (max-width: 1366px) {
  .index-media .index-media-cont .swiper-button-prev:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-next:after,
  .index-media .index-media-cont .swiper-button-next:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-prev:after {
    font-size: 32px;
  }
}

@media (max-width: 991px) {
  .index-media .index-media-cont .swiper-button-prev:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-next:after,
  .index-media .index-media-cont .swiper-button-next:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-prev:after {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .index-media .index-media-cont .swiper-button-prev:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-next:after,
  .index-media .index-media-cont .swiper-button-next:after, .index-media .index-media-cont .swiper-container-rtl .swiper-button-prev:after {
    font-size: 24px;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  font-family: font-article;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .loading-cont img {
  display: block;
  transition: .3s;
  animation: rotate 2s linear infinite;
}

.loading .loading-cont span {
  display: block;
  font-size: 16px;
  padding-top: 25px;
  text-align: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
