@import "../../assets/styles/utils";
@import "../../assets/styles/swiper-bundle.min.css";


.index-vote{
  .vote-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 1000;
  }
  .vote-img{
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: auto;
    right: auto;
    transform: translate3d(-50%,-50%,0);
    z-index: 1001;
    transition: .3s;
    @include res(width,325px, 250 / 325);
    a img{
      width: 100%;
      height: auto;
      transition: .3s;
    }
    .vote-close{
      position: absolute;
      top: -30px;
      right: -30px;
      cursor: pointer;
    }
    &.small-vote{
      top:auto;
      left: auto;
      bottom: 10px;
      right: 10px;
      transform: translate3d(0,0,0);
      @include res(width,100px, 80 / 100);
      .vote-close{
        display: none;
      }
    }
  }
}

.banner {
    height: 100vh;
    .banner-swiper{
      width: 100%;
      height: 100%;
      .swiper-slide {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        .banner-item-pic{
          display: block;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          &.mobile{
            display: none;
          }
        }
        .banner-item-text{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          padding-left: vw(140);
          @include res(padding-top,200px, 100 / 200);

          h1{
            color: #fff;
            font-family:font-title;
            transition: all .6s ease .3s;
            opacity: 0;
            transform: translateX(20%);
            @include res(font-size, 32px, 16 / 32);
          }
          h2{
            color: #fff;
            font-family:font-title;
            transition: all .6s ease .4s;
            opacity: 0;
            transform: translateX(25%);
            @include res(line-height, 1.2);
            @include res(font-size, 68px, 22 / 68);
            @include res(margin-bottom, 10px);
          }
          .banner-item-more {
            border: 2px solid #fff;
            text-align: center;
            line-height: 1.8;
            font-family:font-title;
            transition: all .6s ease .5s;
            opacity: 0;
            transform: translateX(25%);
            @include res(font-size, 22px, 14 / 22);
            @include res(width, 210px, 140 / 210);
            a{
               display: block;
               transition: 0.2s;
               color: #fff;
               i {
                 margin-left: 10px;
                 transition: 0.2s;
                 @include res(font-size, 24px, 16 / 24);
               }
               &:hover {
                  box-shadow: 8px 8px 0 #fff;
                 i{
                  margin-left: 30px;
                 }
               }
            }
          }
        }
        &.swiper-slide-active{
          .banner-item-text{
             h1,
             h2,
             .banner-item-more {
              opacity: 1;
              transform: translateX(0);
             }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .banner-swiper {
        .swiper-slide {
          .banner-item-pic {
            &.pc{
              display: none;
            }
            &.mobile {
              display: block;
            }
          }
        }
      }
    }
    .index-banner-pagination {
      $duration: .3s;
      $size-r: 8px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      padding-top: 5px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      z-index: 9;
      transform: translateX(-50%);
      @include res(margin-right, 28px, (xs: 0));
      // @include res(order, null, (xs: 1));

      .list {
        // display: flex;
        // flex-direction: column;
        // 仅为了 swiper 取值
        transition-duration: $duration;
        @include res(flex-direction, column, (xs: row));
        @include res(margin-bottom, 30px, 0);
        @include res(margin-top, null, (xs: 12px));
      }

      .item {
        position: relative;
        cursor: pointer;
        // float: left;
        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      .circle {
        fill: transparent;
        transition: all $duration;
        transform: scale(0.35);
        transform-origin: center center;
      }

      .circle.anime {
        stroke: $color-red;
        stroke-width: 3px;
        stroke-dasharray: 2 * 3.14 * strip-unit($size-r);
        stroke-dashoffset: 2 * 3.14 * strip-unit($size-r);
        opacity: 0;
        transition: all $duration;
      }

      .circle.bg {
        fill: #fff;
        // fill: transparent;
        stroke-width: 2px;
      }

      // active
      .item.active {
        transform: scale(1);

        .circle {
          transform: scale(1);
        }

        .circle.anime {
          opacity: 1;
          animation-name: active;
          animation-timing-function: linear;
          animation-delay: $duration;
          animation-fill-mode: forwards;
        }

        .circle.bg {
          fill: transparent;
          stroke: #fff;
        }
      }

      @keyframes active {
        0% {
          stroke-dashoffset: 2 * 3.14 * $size-r;
        }

        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes rain {
        0% {
          transform: translateY(5px);
          opacity: 1;
        }

        80% {
          transform: translateY(15px);
          opacity: 0;
        }

        100% {
          transform: translateY(15px);
          opacity: 0;
        }
      }
    }
}
@media (max-width: 991px){
  .comp-header-lang {
      	display: none!important;
	}
}



// 首页关于我们
.about-cont{
  position: relative;
  // overflow: hidden;
  @include res(margin-bottom, 85px, 20 / 85);
}
.about{
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  // @include res(padding-left, 275px, 20 / 275);
  // @include res(padding-right, 275px, 20 / 275);
  font-family:font-title;
  position: relative;
  &::after{
    content: '';
    display: block;
    height: 0;
    clear: both;
  }
  .title{
    color: $color-blue-light;
    @include res(font-size, 40px, 20 / 40);
    @include res(padding-top, 90px, 40 / 90);
    @include res(margin-bottom, 60px, 30 / 60);
    span{
      position: relative;
      &::after{
        content: '';
        width: 100%;
        height: 15px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #d1d7e9;
        z-index: -1;
      }
    }
  }
  .video{
    width: 60%;
    float: left;
    // @include res(height, 440px, 400 / 440);
    box-shadow: 30px 20px 0px #efefef;
    position: relative;
    .video-img{
      position: relative;
      cursor: pointer;
      .img1{
        display: block;
        width: 100%;
        height: auto;
      }
      .img2{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
      }
    }
    .video-cont{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: none;
      background: #000;
      video{
        width: 100%;
        height: 100%;
      }
      &.active{
        display: block;
      }
    }
  }

  .text{
    width: 40%;
    float: left;
    p{
      color: #333;
      // @include res(font-size, 20px, 14 / 20);
      line-height: 1.8;
      padding: 10px 0;
      font-family:font-article;
    }
    .btn{
      border: 2px solid #000;
      @include res(width, 220px, 160 / 220);
      text-align: center;
      line-height: 2.2;
      margin: 0 auto;
      @include res(margin-top, 30px, 20 / 30);
      transition: 0.15s linear;
      a{
        color: #000;
        @include res(font-size, 22px, 16 / 22);
        display: block;
        transition: 0.15s linear;
        font-family:font-title;
        i{
          @include res(font-size, 22px, 16 / 22);
          transition: 0.2s;
        }
      }
      &:hover{
        box-shadow: 5px 5px 0px #000;
      }
      &:hover i{
        margin-left: 18px;
      }
    }
  }
}
@media (max-width: 991px){
  .about .video{
    width: 100%;
  }
  .about .text{
    width: 100%;
    margin-top: 20px;
  }
  .pl-16{
    padding-left: 0 !important;
  }
  .float{
    display: none;
  }
}
@media (max-width:768px){
  .about .video{
    box-shadow: none;
  }
  .about .text{
    margin-top: 0;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text h1{
    color: #fff;
  }
  .banner .banner-swiper .swiper-slide .banner-item-text h2{
    color: #fff;
  }
}
@media (max-width:640px) {
  .about .text {
    padding-top: 0;
  }
}
@media (max-width:378px){
  .about .video{
    height: 240px;
  }
  .about .text{
    margin-top:0;
  }
}

// 右侧浮动
.float{
  width:128px;
  position: absolute;
  top:0;
  right: 0;
  z-index: 999;
  // transition:all 0.3s;
  img{
    width: 100%;
  }
}
@media (max-width:1920px){
  .float{
    width: 90px;
  }
}
@media (max-width:1600px){
  .float{
    width: 80px;
  }
}
@media (max-width:1366px){
  .float{
    width: 70px;
  }
}
@media (max-width:1024px){
  .float{
    width: 70px;
  }
}

// 首页产品
.pc-product{
  width: 100%;
  height: auto;
  .max_list{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    li{
      width: 33.33vw;
      // width: 33.3333333333%;
      // overflow: hidden;
      height:100%;
      cursor: pointer;
      transition: 0.2s linear;
      flex-shrink: 0;
      .border_top{
        width: 100%;
        @include res(height, 30px, 20 / 30);
        position: relative;
        .back{
          width: 100%;
          height: 0px;
          transition: 0.2s linear;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 8;
        }
      }
      .img{
        width: calc(100% + 1px);
        height: auto;
        overflow: hidden;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin:0 auto;
        }
        .mask{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            filter: grayscale(100%);
            transition:0.3s linear;
            opacity: 0.3;
          }
          .desc{
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
            @include res(padding-left, 130px, 30 / 130);
            @include res(padding-top, 108px, 38 / 108);
            h1{
              color: #fff;
              @include res(font-size, 80px, 16 / 80);
              @include res(margin-bottom, 100px, 30 / 100);
              font-family:font-title;
              line-height: 0.8;
            }
            dl dt{
              width: 100%;
              line-height: 1.8;
              transition: 0.2s linear;
              position: relative;
              z-index: 99;
              .back{
                position: absolute;
                width:0;
                height: 100%;
                line-height: 1.8;
                top: 0;
                left: 0;
                z-index: 9;
                transition: 0.25s ease-out;
              }
              a{
                color: #fff;
                @include res(font-size, 24px, 14 / 24);
                padding-left: 8px;
                transition: 0.2s linear;
                font-family:font-title;
                position: relative;
                z-index: 99;
              }
            }
            .right_icon{
              color: #fff;
              opacity: 0;
              visibility: hidden;
              transition: all .25s;
              @include res(margin-top, 82px, 50 / 82);
              @include res(margin-left, -48px, -20 / -48);
              i{
                @include res(font-size, 48px, 20 / 48);
              }
            }
          }
        }
      }
      &:hover{
        // transform: scale(1.1);
        position: relative;
        z-index: 9;
      }
    }
    li:nth-child(1) .desc h1:nth-child(1),
    li:nth-child(2) .desc h1:nth-child(1),
    li:nth-child(4) .desc h1:nth-child(1){
      margin-bottom: 0;
    }
    li:nth-child(1):hover .mask .desc h1{
      color: $color-yellow;
    }
    li:nth-child(2):hover .mask .desc h1{
      color: $color-bluegreen;
    }
    li:nth-child(3):hover .mask .desc h1{
      color: $color-red;
    }
    li:nth-child(4):hover .mask .desc h1{
      color: $color-sky-blue;
    }
    li:nth-child(5):hover .mask .desc h1{
      color: $color-blue;
    }
    li:hover .img .mask img{
      filter:none;
      transition:0.2s linear;
      opacity: 1;
    }
    
    li:nth-child(1) .desc dl dt:hover .back{
      background:$color-yellow;
      width: 100%;
    }
    li:nth-child(2) .desc dl dt:hover .back{
      background:$color-bluegreen;
      width: 100%;
    }
    li:nth-child(3) .desc dl dt:hover .back{
      background:$color-red;
      width: 100%;
    }
    li:nth-child(4) .desc dl dt:hover .back{
      background:$color-sky-blue;
      width: 100%;
    }
    li:nth-child(5) .desc dl dt:hover .back{
      background:$color-blue;
      width: 100%;
    }
    .desc dl dt:hover a{
      color: #000 !important;
    }
    li:hover .img .mask .desc .right_icon{
      opacity: 0;
      visibility: visible;
      margin-left:0;
    }
  }
}
.product_title{
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  color: $color-yellow;
  font-family:font-title;
  @include res(font-size, 40px, 20 / 40);
  @include res(margin-bottom,60px, 0 / 60);
  span {
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 15px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fef2cc;
      z-index: -1;
    }
  }
}
.xscroll{
    position: relative;
    overflow: visible;
    .xscroll-tab-cont{
        width: 100%;
        top: 0;
        bottom: auto;
        left: 0;
        @include res(position,absolute);
        &.fixed{
            position: fixed;
            top: 0;
            left: 0;
        }
        &.abs{
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
        }

        .tab{
            top: 80px;
            left: 0;
            z-index: 1;
            width: 100%;
            @include res(position,absolute);
            @include res(margin-top,0,(md:60px,sm:40px,xs:30px));
            .tab-cont{
                align-items: flex-start;
                justify-content: flex-start;
                @include res(display,flex,(md:none));
            }
        }
    }
    .xscroll-cont{
        display: flex;
        overflow: visible;
        height: 100vh;
        align-items: center;
        .x{
          display: flex;
          overflow: visible;
          
        }
    }
}



.mob-product{
  display: none;
  .min_list{
    li{
      width: 100%;
      height: auto;
      overflow: hidden;
    }
  }
}
@media (max-width:415px){
  .desc{
    padding: 10px 0 0 10px !important;
  }
  .desc h1{
    margin-bottom: 10px !important;
  }
  .desc .right_icon{
    margin-top: 10px !important;
  }
}
@media (max-width:768px){
  .product.pc-product{
    display: none;
  }
  .product.mob-product{
    display: block;
  }
  .product .min_list{
    display:block;
    width: 94%;
    height: auto;
    margin: 20px auto 0;
    margin-top: 30px;
    li{
      width: 100%;
      height: auto;
      overflow: hidden;
      margin-bottom: 20px;
      padding:15px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // z-index: 0;
        background: rgba(0,0,0,0.2);
      }
      h1{
        font-size: 20px;
        color: #fff;
        font-family:font-title;
        margin-bottom: 10px;
        position: relative;
        z-index: 1;
      }
      dl{
        position: relative;
        z-index: 1;
        dd{
          line-height: 24px;
          a{
            font-size: 16px;
            color: #fff;
            font-family:font-title;
            display: block;
          }
        }
      }
    }
  }
}
@media (max-width:991px){
  .product .product_title .line{
    margin-bottom: 6px;
  }
}

// 首页新闻
@media (max-width:768px){
  // .news{
  //   display: none;
  // }
  // .mobile_news{
  //   display: block;
  // }
}
.index-news_title {
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  padding: 0 20px;
  color: $color-red;
  font-family: font-title;
  @include res(font-size, 40px, 20 / 40);
  @include res(margin-top, 100px, 50 / 100);

  span {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 15px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #ffd7d0;
      z-index: -1;
    }
  }
}
.news{
  padding-left: 270px;
  margin-top: 50px;
  margin-bottom: 30px;
  .swiper-wrapper{
    box-sizing: border-box !important;
  }
  ul{
    // @include res(padding-left, 275px, 20 / 275);
  }
  ul li{
    overflow: hidden;
    position: relative;
    .img{
      width: 100%;
      height: 100%;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        z-index: 1;
        width: 100%;
        height: auto;
        display: block;
        margin:0 auto;
        transition:all .3s ease-out 0s;
      }
      b {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: all .3s ease-out 0s;
      }
      .mask{
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
      }
      .con{
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;
        min-height: 400px;
        position: relative;
        z-index: 999;
        background: #efefef;
        transition:all .3s ease-out 0s;
        @include res(padding, 36px, 15 / 36);
        // @include res(padding-left, 36px, 15 / 36);
        // @include res(padding-right, 36px, 15 / 36);
        .date{
          display: flex;
          align-items: center;
          .spot{
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: $color-red;
            transition:all .3s ease-out 0s;
          }
          .date_time{
            flex: 1;
            text-align: right;
            transition:all .3s ease-out 0s;
            font-family:font-article;
          }
        }
        h1{
          @include res(font-size, 28px, 16 / 28);
          @include res(margin-top, 36px, 16 / 36);
          @include res(margin-bottom, 36px, 16 / 36);
          transition:all .3s ease-out 0s;
          font-family:font-title;
          color: #333333;
        }
        .desc{
          // @include res(font-size, 20px, 12 / 20);
          line-height: 1.4;
          transition:all .3s ease-out 0s;
          font-family:font-article;
        }
      }
    }
    :hover .con{
      background: none;
    }
    :hover .img img{
      transform:translate3d(-50%, -50%, 0) scale(1.05);
    }
    :hover .img b {
      transform: scale(1.05);
    }
    :hover .img .con .date .spot{
      background: #fff;
    }
    :hover .img .con .date_time{
      color: #fff;
    }
    :hover .img .con h1{
      color: #fff;
    }
    :hover .img .con .desc{
      color: #fff;
    }
  }
  .swiper-button-prev{
    left: 3% !important;
    opacity: 1;
  }
  .swiper-button-next{
    right: 3% !important;
  }
}
@media (max-width:1024px) {
  .news{
    padding-left: 100px;
  }
}
@media (max-width:768px){
  .news {
    padding-left: 0px;
    margin-top: 30px;
  }
  .news ul{
    padding-left: 0;
    li{
      // width: 100% !important;
      margin-right: 0;
      .img .con{
        background: none;
        .date,h1,.desc{
          color: #fff;
        }
      }
    }
  }
}
@media (max-width:414px){
  .news ul{
    padding-left: 0;
    li{
      width: 100% !important;
      margin-right: 0;
    }
  }
}
.swiper-button-next1,.swiper-button-next2{
  @include res(width, 54px, 27 / 54);
  @include res(height, 54px, 27 / 54);
  border-radius: 50%;
  overflow: hidden;
  background: url(./images/icon_right.png) no-repeat center;
  color: transparent;
}
.swiper-button-prev1,.swiper-button-prev2{
  @include res(width, 54px, 27 / 54);
  @include res(height, 54px, 27 / 54);
  border-radius: 50%;
  overflow: hidden;
  background: url(./images/icon_left.png) no-repeat center;
  color: transparent;
}
.swiper-button-next1:focus,.swiper-button-prev1:focus,.swiper-button-next2:focus,.swiper-button-prev2:focus{
  outline: none;
}
.my-button-disabled {
  opacity: 0.6 !important;
}

.index-media-title{
    color: #25b3e8;
    font-weight: bold;
    font-family:'font-title';
    width: 100%;
    max-width: 1405px;
    margin: 0 auto;
    padding: 0 20px;
    @include res(font-size, 40px, 20 / 40);
    @include res(padding-top, 150px, 40 / 150);
    @include res(margin-bottom, 60px, 30 / 60);
    span{
      position: relative;
      &::after{
        content: '';
        width: 100%;
        height: 15px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #d3f0fa;
        z-index: -1;
      }
    }
  }
.index-media{
  width: 100%;
  max-width: 1405px;
  margin: 0 auto;
  @include res(padding-left, 20px, 0 / 20);
  @include res(padding-right, 20px, 0 / 20);

  .index-media-cont{
    position: relative;
    @include res(height, 760px, 300 / 760);
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    .gallery-top {
      height: 100%;
      width: 100%;
      position: relative;
      // .swiper-slide a{
      //   display: block;
      //   height: 100%;
      // }
      .media-link{
        width: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.3);
        top: 0;
        z-index: 2;
        transition: .3s;
        display: flex;
        @include res(bottom, 150px, 80 / 150);
        @include res(opacity, 0, (
          sm: 1
        ));
        .btn{
          border: 2px solid #fff;
          text-align: center;
          line-height: 2.2;
          font-family:font-title;
          transition: all .6s ease .5s;
          @include res(font-size, 30px, 18 / 30);
          @include res(width, 290px, 160 / 290);
          a{
             display: block;
             transition: 0.2s;
             color: #fff;
             i {
               margin-left: 10px;
               transition: 0.2s;
               @include res(font-size, 34px, 18 / 34);
             }
             &:hover {
                box-shadow: 8px 8px 0 #fff;
               i{
                @include res(margin-left,30px, 15 / 30)
               }
             }
          }
        }
      }
      &:hover{
        .media-link{
          opacity: 1;
        }
      }
    }
    .gallery-thumbs {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @include res(height, 150px, 80 / 150);
    }
    .gallery-thumbs .swiper-slide {
      width: 11.11%;
      height: 100%;
      position: relative;
      cursor: pointer;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        background: rgba(0,0,0,0.5);
      }
    }
    .gallery-thumbs .swiper-slide-thumb-active {
      &::after{
        background: rgba(0,0,0,0);
      }
    }
    .swiper-button-next, .swiper-button-prev{
      top: 43%;
      @include res(display, flex, (
        sm: none
      ))
    }
    .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after,
    .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
      @include res(font-size, 36px, 24 / 36)
    }
  }
}

// loading
.loading{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  font-family: font-article;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-cont{
    img{
      display: block;
      transition: .3s;
      animation: rotate 2s linear infinite;
    }
    span{
      display: block;
      font-size: 16px;
      padding-top: 25px;
      text-align: center;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
